const social = [
  {
    text: "twitter",
    path: "https://twitter.com",
  },
  {
    text: "github",
    path: "https://twitter.com",
  },
  {
    text: "codepen",
    path: "https://twitter.com",
  },
]

export default social
