const routes = [
  // {
  //   text: "home",
  //   path: "/",
  // },
  {
    text: "react",
    path: "/tag/react",
  },
  {
    text: "javascript",
    path: "/tag/javascript",
  },
  {
    text: "node js",
    path: "/tag/node%20js",
  },
  {
    text: "style",
    path: "/tag/style",
  },
]

export default routes
